body {
  display: flex;
  flex-flow: column;
  min-height: 100vh;
}

main {
  flex: 1;
}

footer {
  position: sticky;
  top: 100vh;
}
